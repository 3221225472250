import React, { useState, useEffect } from "react";
import AppHeader from "../../../components/AppHeader/AppHeader";
import PreLoader from "../../../components/common/PreLoader";
import {
  apiAddUserGroups,
  apiRemoveUserGroups,
  apiUpdateUser,
} from "../../../services/api/userApi.service";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import UserForm from "../UserForm";
import { useDispatch } from "react-redux";
import { setAppTitle } from "../../../store/slices/settingsSlice";
import { useSelector } from "react-redux";
import { apiGetUserGroups } from "../../../services/api/userApi.service";

const EditUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [Loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const countryCode = localStorage.getItem("i18nextLng").split("_")[1];
  const countryDialCode = {
    ZM: "+260",
    ZA: "+27",
    UG: "+256",
  };
  const { selectedUserData } = useSelector((state) => state.settings);
  const [userGroupsList, setUserGroupsList] = useState([]);

  const updateUser = async (values) => {
    let _removeUserGroups = [];
    let _addUserGroups = [];

    values.userGroups.forEach((element) => {
      element.isSelected
        ? _addUserGroups.push(element.id)
        : _removeUserGroups.push(element.id);
    });

    const _userObj = {
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      locale: `en_${countryCode}`,
      phone_number: values.mobileNo,
      phone_number_verified: true,
      preferred_username: countryCode,
      site_id: values.siteId,
      user_cache: "5555",
    };

    setLoading(true);
    const results = await apiUpdateUser(_userObj);
    const addUserGroupsResults = await apiAddUserGroups({
      email: values.email,
      groups: _addUserGroups.join(","),
    });
    const removeUserGroupsResults = await apiRemoveUserGroups({
      email: values.email,
      groups: _removeUserGroups.join(","),
    });

    if (
      results.success &&
      addUserGroupsResults.success &&
      removeUserGroupsResults.success
    ) {
      enqueueSnackbar("Updated Successfully.", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setLoading(false);
      history.push({ pathname: "/manage-users" });
    } else {
      enqueueSnackbar(results.message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setLoading(false);
    }
  };

  const getUserGroups = async () => {

    const _userGroupsEnv = JSON.parse(process.env.REACT_APP_COGNITO_USER_GROUPS);
    let _userGroupsArray = [];

    setLoading(true);
    _userGroupsEnv.forEach((group) => {
      _userGroupsArray.push({
        id: group,
        label: group,
        isSelected: false,
      });
    });

    const results = await apiGetUserGroups({
      email: selectedUserData.email,
    });

    if (results.success) {
      const _currentUserGroups = JSON.parse(results.body.groups);
      let _userGroups = [..._userGroupsArray];

      _userGroups.forEach((userGroup) => {
        if (_currentUserGroups.indexOf(userGroup.id) > -1) {
          userGroup.isSelected = true;
        }
      });

      setUserGroupsList([..._userGroups]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getUserGroups();
    }, 50);
  }, [selectedUserData]);

  useEffect(() => {
    setLoading(false);
    dispatch(setAppTitle("Edit User"));
  }, []);

  return (
    <>
      <AppHeader isGuestMode={false} heading={`Add User`} />
      <UserForm
        handleFormSubmit={updateUser}
        isEditMode={true}
        userObj={selectedUserData}
        userGroupsList={userGroupsList}
      />
      <PreLoader show={Loading} />
    </>
  );
};

export default EditUser;
