import React, { useState, useEffect } from "react";
import AppHeader from "../../../components/AppHeader/AppHeader";
import PreLoader from "../../../components/common/PreLoader";
import {
  apiAddUserGroups,
  apiCreateUser,
  apiRemoveUserGroups,
} from "../../../services/api/userApi.service";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import UserForm from "../UserForm";
import { useDispatch } from "react-redux";
import { setAppTitle } from "../../../store/slices/settingsSlice";

const AddUser = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [Loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const countryCode = localStorage.getItem("i18nextLng").split("_")[1];
  const countryDialCode = {
    ZM: "+260",
    ZA: "+27",
    UG: "+256",
  };
  const [userGroupsList, setUserGroupsList] = useState([]);

  const createUser = async (values) => {
    let _removeUserGroups = [];
    let _addUserGroups = [];

    values.userGroups.forEach((element) => {
      element.isSelected
        ? _addUserGroups.push(element.id)
        : _removeUserGroups.push(element.id);
    });

    const _userObj = {
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      locale: `en_${countryCode}`,
      phone_number: `${countryDialCode[countryCode]}${values.mobileNo}`,
      phone_number_verified: true,
      preferred_username: countryCode,
      site_id: values.siteId,
      user_cache: "5555",
    };

    setLoading(true);
    const results = await apiCreateUser(_userObj);

    const addUserGroupsResults = await apiAddUserGroups({
      email: values.email,
      groups: _addUserGroups.join(","),
    });
    const removeUserGroupsResults = await apiRemoveUserGroups({
      email: values.email,
      groups: _removeUserGroups.join(","),
    });

    if (
      results.success &&
      addUserGroupsResults.success &&
      removeUserGroupsResults.success
    ) {
      enqueueSnackbar("Successfully created.", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setLoading(false);
      history.push({ pathname: "/manage-users" });
    } else {
      enqueueSnackbar(results.message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const _userGroups = JSON.parse(process.env.REACT_APP_COGNITO_USER_GROUPS);
    let _userGroupsArray = [];

    _userGroups.forEach(group => {
      _userGroupsArray.push({
        id: group,
        label: group,
        isSelected: false,
      });
    });
    setUserGroupsList(_userGroupsArray);
    dispatch(setAppTitle("Add New User"));
  }, []);

  return (
    <>
      <AppHeader isGuestMode={false} heading={`Add User`} />
      <UserForm handleFormSubmit={createUser} userGroupsList={userGroupsList} />
      <PreLoader show={Loading} />
    </>
  );
};

export default AddUser;
